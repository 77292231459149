/* Change this file to get your personal Portfolio */

// To change portfolio colors globally go to the  _globalColor.scss file

import emoji from "react-easy-emoji";
import splashAnimation from "./assets/lottie/splashAnimation"; // Rename to your file name for custom animation

// Splash Screen

const splashScreen = {
  enabled: true, // set false to disable splash screen
  animation: splashAnimation,
  duration: 2000 // Set animation duration as per your animation
};

// Summary And Greeting Section

const illustration = {
  animated: true // Set to false to use static SVG
};

const greeting = {
  username: "Mavro Coins",
  title: "Welcome to Mavro Coins",
  subTitle: emoji(
    "Mavro Coin (MAVRO) is more than just a cryptocurrency—it's a tribute to the visionary Sergey Mavrodi, the legendary financial revolutionary. Built on the robust and efficient Binance Smart Chain (BSC), Mavro Coin aims to redefine the landscape of decentralized finance (DeFi) by fostering a community-driven ecosystem that champions financial freedom and innovation."
  ),
  resumeLink:
    "https://drive.google.com", // Set to empty to hide the button
  displayGreeting: true // Set false to hide this section, defaults to true
};

// Social Media Links

const socialMediaLinks = {
  github: "https://twitter.com/MMMGO1188722",
  linkedin: "https://t.me/mavro_community",
  gmail: "admin@mavroasset.com",
  gitlab: "https://gitlab.com/saadpasta",
  facebook: "https://www.facebook.com/mavrocoin",
  medium: "https://medium.com/@mavrocoin",
  stackoverflow: "https://stackoverflow.com/users/mavrocoin",
  // Instagram, Twitter and Kaggle are also supported in the links!
  // To customize icons and social links, tweak src/components/SocialMedia
  display: true // Set true to display this section, defaults to false
};

// Skills Section

const skillsSection = {
  title: "Features of Mavro",
  subTitle: "Revolutionizing the World of Finanancial Tokens In the ever-evolving landscape of cryptocurrency and blockchain technology, innovative applications continue to emerge, each seeking to disrupt traditional industries.",
  skills: [
   
    emoji(
      "⚡ Ultra-Fast Transactions: Experience lightning-fast transaction speeds, thanks to the advanced capabilities of the Binance Smart Chain. Say goodbye to long wait times and high fees."
    ),
    emoji(
      "⚡ Low Fees: Enjoy minimal transaction costs, making Mavro Coin perfect for everyday use and large-scale operations alike. Save more with each transaction and reinvest your savings."
    ),
    emoji(
      "⚡Smart Contract Integration: Leverage the power of smart contracts to automate processes, ensure transparency, and create trustless agreements. Mavro Coin is your gateway to decentralized applications (DApps) and the booming world of DeFi."
    ),
    emoji(
      "⚡Secure and Scalable: Security is our top priority. With the Binance Smart Chain’s robust infrastructure, your transactions and data are protected. Plus, scalability ensures Mavro Coin can grow with your needs."
    ),
  ],



  /* Make Sure to include correct Font Awesome Classname to view your icon
https://fontawesome.com/icons?d=gallery */

  softwareSkills: [],
  display: true // Set false to hide this section, defaults to true
};
const skillsSection2 = {
  title: "Legacy of Innovation",
  subTitle: "Sergey Mavrodi's legacy of financial disruption and community empowerment lives on through Mavro Coin. By leveraging blockchain technology, MAVRO continues Mavrodi's mission of democratizing access to financial opportunities",
  skills: [
   
    emoji(
      "⚡ Secure and Efficient: Powered by Binance Smart Chain, Mavro Coin ensures fast, secure, and low-cost transactions. The BSC infrastructure offers unparalleled speed and scalability, making MAVRO a reliable asset in your digital portfolio."
    ),
    emoji(
      "⚡ Community-Centric: Mavro Coin is designed with the community at its core. Holders of MAVRO are more than investors—they are part of a movement. Participate in governance, vote on key decisions, and shape the future of the MAVRO ecosystem."
    ),
    emoji(
      "⚡Smart Contract Integration: Leverage the power of smart contracts to automate processes, ensure transparency, and create trustless agreements. Mavro Coin is your gateway to decentralized applications (DApps) and the booming world of DeFi."
    ),
    emoji(
      "⚡Secure and Scalable: Security is our top priority. With the Binance Smart Chain’s robust infrastructure, your transactions and data are protected. Plus, scalability ensures Mavro Coin can grow with your needs."
    ),
  ],



  /* Make Sure to include correct Font Awesome Classname to view your icon
https://fontawesome.com/icons?d=gallery */

  softwareSkills: [
    {
      skillName: "html-5",
      fontAwesomeClassname: "fab fa-html5"
    },
    {
      skillName: "css3",
      fontAwesomeClassname: "fab fa-css3-alt"
    },
    {
      skillName: "sass",
      fontAwesomeClassname: "fab fa-sass"
    },
    {
      skillName: "JavaScript",
      fontAwesomeClassname: "fab fa-js"
    },
    {
      skillName: "reactjs",
      fontAwesomeClassname: "fab fa-react"
    },
    {
      skillName: "nodejs",
      fontAwesomeClassname: "fab fa-node"
    },
    {
      skillName: "swift",
      fontAwesomeClassname: "fab fa-swift"
    },
    {
      skillName: "npm",
      fontAwesomeClassname: "fab fa-npm"
    },
    {
      skillName: "sql-database",
      fontAwesomeClassname: "fas fa-database"
    },
    {
      skillName: "aws",
      fontAwesomeClassname: "fab fa-aws"
    },
    {
      skillName: "firebase",
      fontAwesomeClassname: "fas fa-fire"
    },
    {
      skillName: "python",
      fontAwesomeClassname: "fab fa-python"
    },
    {
      skillName: "docker",
      fontAwesomeClassname: "fab fa-docker"
    }
  ],
  display: true // Set false to hide this section, defaults to true
};
const skillsSection3 = {
  title: "Transparent",
  subTitle: "Transparency is key. Mavro Coin operates on a fully transparent blockchain, where every transaction is publicly verifiable. This openness builds trust and fosters a robust, engaged community.",
  skills: [
   
    emoji(
      "⚡ Rewarding Participation: Staking MAVRO coins not only secures the network but also rewards you. Earn passive income while supporting the ecosystem. The more you stake, the greater your rewards."
    ),
    emoji(
      "⚡ Low Fees: Enjoy minimal transaction costs, making Mavro Coin perfect for everyday use and large-scale operations alike. Save more with each transaction and reinvest your savings."
    ),
    emoji(
      "⚡Smart Contract Integration: Leverage the power of smart contracts to automate processes, ensure transparency, and create trustless agreements. Mavro Coin is your gateway to decentralized applications (DApps) and the booming world of DeFi."
    ),
    emoji(
      "⚡Secure and Scalable: Security is our top priority. With the Binance Smart Chain’s robust infrastructure, your transactions and data are protected. Plus, scalability ensures Mavro Coin can grow with your needs."
    ),
  ],



  /* Make Sure to include correct Font Awesome Classname to view your icon
https://fontawesome.com/icons?d=gallery */

  softwareSkills: [],
  display: true // Set false to hide this section, defaults to true
};
const skillsSection4 = {
  title: "Join the Mavro",
  subTitle: "",
  skills: [
   
    emoji(
      "⚡ Get a Wallet: Choose a Binance Smart Chain-compatible wallet, such as Trust Wallet or MetaMask."
    ),
    emoji(
      "⚡ Buy MAVRO: Acquire Mavro Coin on major exchanges supporting BSC tokens."
    ),
    emoji(
      "⚡Stake and Earn: Stake your MAVRO tokens in the official staking pools and start earning rewards."
    ),
    emoji(
      "⚡Participate: Engage with the community, join discussions, and vote on proposals to influence the future of Mavro Coin."
    ),
  ],



  /* Make Sure to include correct Font Awesome Classname to view your icon
https://fontawesome.com/icons?d=gallery */

  softwareSkills: [],
  display: true // Set false to hide this section, defaults to true
};
const skillsSection5 = {
  title: "The Vision Forward",
  subTitle: "Mavro Coin is dedicated to creating a sustainable and inclusive financial ecosystem.",
  skills: [
   
    emoji(
      "⚡ Decentralized Applications (DApps):  Launching innovative DApps to enhance user experience and utility."
    ),
    emoji(
      "⚡ Partnerships: Collaborating with global partners to expand the reach and adoption of MAVRO."
    ),
    emoji(
      "⚡Enhanced Security: Continually improving security protocols to protect our community and assets."
    ),
    emoji(
      "⚡Embrace the spirit of Sergey Mavrodi and join the Mavro Coin movement. Together, we can build a decentralized future where financial freedom and innovation thrive. Be part of a legacy. Be part of Mavro Coin."
    ),
  ],



  /* Make Sure to include correct Font Awesome Classname to view your icon
https://fontawesome.com/icons?d=gallery */

  softwareSkills: [
    {
      skillName: "html-5",
      fontAwesomeClassname: "fab fa-html5"
    },
    {
      skillName: "css3",
      fontAwesomeClassname: "fab fa-css3-alt"
    },
    {
      skillName: "sass",
      fontAwesomeClassname: "fab fa-sass"
    },
    {
      skillName: "JavaScript",
      fontAwesomeClassname: "fab fa-js"
    },
    {
      skillName: "reactjs",
      fontAwesomeClassname: "fab fa-react"
    },
    {
      skillName: "nodejs",
      fontAwesomeClassname: "fab fa-node"
    },
    {
      skillName: "swift",
      fontAwesomeClassname: "fab fa-swift"
    },
    {
      skillName: "npm",
      fontAwesomeClassname: "fab fa-npm"
    },
    {
      skillName: "sql-database",
      fontAwesomeClassname: "fas fa-database"
    },
    {
      skillName: "aws",
      fontAwesomeClassname: "fab fa-aws"
    },
    {
      skillName: "firebase",
      fontAwesomeClassname: "fas fa-fire"
    },
    {
      skillName: "python",
      fontAwesomeClassname: "fab fa-python"
    },
    {
      skillName: "docker",
      fontAwesomeClassname: "fab fa-docker"
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Education Section

const educationInfo = {
  display: true, // Set false to hide this section, defaults to true
  schools: [
    {
      schoolName: "Binace Feed",
      logo: require("./assets/images/binance.jpg"),
      subHeader: "",
      duration: "",
      desc: "",
      descBullets: [
        
      ]
    },
  ]
};

// Your top 3 proficient stacks/tech experience

const techStack = {
  viewSkillBars: true, //Set it to true to show Proficiency Section
  experience: [
    {
      Stack: "Frontend/Design", //Insert stack or technology you have experience in
      progressPercentage: "90%" //Insert relative proficiency in percentage
    },
    {
      Stack: "Backend",
      progressPercentage: "70%"
    },
    {
      Stack: "Programming",
      progressPercentage: "60%"
    }
  ],
  displayCodersrank: false // Set true to display codersrank badges section need to changes your username in src/containers/skillProgress/skillProgress.js:17:62, defaults to false
};

// Work experience section

const workExperiences = {
  display: true, //Set it to true to show workExperiences Section
  experience: [
    {
      role: "",
      company: "MEXC",
      companylogo: require("./assets/images/mexc.png"),
      date: "",
      desc: "",
      descBullets: [
      ]
    },
    {
      role: "",
      company: "GATE",
      companylogo: require("./assets/images/gate.png"),
      date: "",
      desc: ""
    },
    
  ]
};

/* Your Open Source Section to View Your Github Pinned Projects
To know how to get github key look at readme.md */

const openSource = {
  showGithubProfile: "true", // Set true or false to show Contact profile using Github, defaults to true
  display: true // Set false to hide this section, defaults to true
};

// Some big projects you have worked on

const bigProjects = {
  title: "Listing Partners",
  subtitle: "We have listing plans for..",
  projects: [
    {
      image: require("./assets/images/mexc.png"),
      projectName: "MEXC",
      projectDesc: "",
      footerLink: [
        {
          name: "Visit Website",
          url: "http://mexc.com/"
        }
        //  you can add extra buttons here.
      ]
    },
    {
      image: require("./assets/images/gate.png"),
      projectName: "GATE",
      projectDesc: "",
      footerLink: [
        {
          name: "Visit Website",
          url: "http://gate.io/"
        }
      ]
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Achievement Section
// Include certificates, talks etc

const achievementSection = {
  title: emoji("Tokenomics "),
  subtitle:
    "Token Distribution",

  achievementsCards: [
    {
      title: "",
      subtitle:
        "",
      image: require("./assets/images/tokenomics.png"),
      imageAlt: "",
      footerLink: [
       
      ]
    },
   
  ],
  display: true // Set false to hide this section, defaults to true
};

// Blogs Section

const blogSection = {
  title: "Roadmap",
  subtitle:
    "Roadmap for Mavro Coin",
  displayMediumBlogs: "true", // Set true to display fetched medium blogs instead of hardcoded ones
  blogs: [
    {
      url: "",
      title: "Foundation and Launch(Completed)",
      description:
        "Forge strategic partnerships with  stakeholders to expand the project's reach and utility."
    },
    {
      url: "",
      title: "Initial Development and Listing",
      description:
        "Conduct thorough security audits of the MAVRO smart contract by reputable third-party firms.List Mavro Coin on major decentralized exchanges (DEXs) such as PancakeSwap. Introduce a staking platform where users can stake MAVRO tokens and earn rewards."
    },
    {
      url: "",
      title: "Expansion and Integration",
      description:
        "Begin development of decentralized applications (DApps) that enhance the utility of MAVRO tokens."
    },
    {
      url: "",
      title: "Advanced Features and Adoption",
      description:
        "Establish a governance mechanism to enable community-driven decision-making and ensure the project's long-term sustainability and decentralization."
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Talks Sections

const talkSection = {
  title: "TALKS",
  subtitle: emoji(
    "I LOVE TO SHARE MY LIMITED KNOWLEDGE AND GET A SPEAKER BADGE 😅"
  ),

  talks: [
    {
      title: "Build Actions For Google Assistant",
      subtitle: "Codelab at GDG DevFest Karachi 2019",
      slides_url: "https://bit.ly/saadpasta-slides",
      event_url: "https://www.facebook.com/events/2339906106275053/"
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Podcast Section

const podcastSection = {
  title: emoji("Podcast 🎙️"),
  subtitle: "I LOVE TO TALK ABOUT MYSELF AND TECHNOLOGY",

  // Please Provide with Your Podcast embeded Link
  podcast: [
    "https://anchor.fm/codevcast/embed/episodes/DevStory---Saad-Pasta-from-Karachi--Pakistan-e9givv/a-a15itvo"
  ],
  display: true // Set false to hide this section, defaults to true
};

const contactInfo = {
  title: emoji("Contact US"),
  subtitle:
    "We have a dedicated support team",
  number: "",
  email_address: "admin@mavroasset.com"
};

// Twitter Section

const twitterDetails = {
  userName: "mavrocoins", //Replace "twitter" with your twitter username without @
  display: true // Set true to display this section, defaults to false
};

const isHireable = false; // Set false if you are not looking for a job. Also isHireable will be display as Open for opportunities: Yes/No in the GitHub footer

export {
  illustration,
  greeting,
  socialMediaLinks,
  splashScreen,
  skillsSection,
  skillsSection2,
  skillsSection3,
  skillsSection4,
  skillsSection5,
  educationInfo,
  techStack,
  workExperiences,
  openSource,
  bigProjects,
  achievementSection,
  blogSection,
  talkSection,
  podcastSection,
  contactInfo,
  twitterDetails,
  isHireable
};
